// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-apply-js": () => import("./../../src/pages/apply.js" /* webpackChunkName: "component---src-pages-apply-js" */),
  "component---src-pages-balloon-js": () => import("./../../src/pages/balloon.js" /* webpackChunkName: "component---src-pages-balloon-js" */),
  "component---src-pages-balloon-popping-leaderboard-game-js": () => import("./../../src/pages/balloon-popping-leaderboard-game.js" /* webpackChunkName: "component---src-pages-balloon-popping-leaderboard-game-js" */),
  "component---src-pages-balloon-popping-leaderboard-js": () => import("./../../src/pages/balloon-popping-leaderboard.js" /* webpackChunkName: "component---src-pages-balloon-popping-leaderboard-js" */),
  "component---src-pages-big-data-analysis-startup-js": () => import("./../../src/pages/big-data-analysis-startup.js" /* webpackChunkName: "component---src-pages-big-data-analysis-startup-js" */),
  "component---src-pages-blog-details-js": () => import("./../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-index-js": () => import("./../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-style-guide-md": () => import("./../../src/pages/blog/styleGuide.md" /* webpackChunkName: "component---src-pages-blog-style-guide-md" */),
  "component---src-pages-case-studies-details-js": () => import("./../../src/pages/case-studies-details.js" /* webpackChunkName: "component---src-pages-case-studies-details-js" */),
  "component---src-pages-case-studies-js": () => import("./../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-community-js": () => import("./../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-details-js": () => import("./../../src/pages/course-details.js" /* webpackChunkName: "component---src-pages-course-details-js" */),
  "component---src-pages-courses-artificial-intelligence-js": () => import("./../../src/pages/courses/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-courses-artificial-intelligence-js" */),
  "component---src-pages-courses-basic-computer-course-js": () => import("./../../src/pages/courses/basic-computer-course.js" /* webpackChunkName: "component---src-pages-courses-basic-computer-course-js" */),
  "component---src-pages-courses-fee-js": () => import("./../../src/pages/courses/fee.js" /* webpackChunkName: "component---src-pages-courses-fee-js" */),
  "component---src-pages-courses-index-js": () => import("./../../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-courses-web-and-mobile-app-development-js": () => import("./../../src/pages/courses/web-and-mobile-app-development.js" /* webpackChunkName: "component---src-pages-courses-web-and-mobile-app-development-js" */),
  "component---src-pages-coursesss-js": () => import("./../../src/pages/coursesss.js" /* webpackChunkName: "component---src-pages-coursesss-js" */),
  "component---src-pages-create-js": () => import("./../../src/pages/create.js" /* webpackChunkName: "component---src-pages-create-js" */),
  "component---src-pages-data-analytics-ai-startup-js": () => import("./../../src/pages/data-analytics-ai-startup.js" /* webpackChunkName: "component---src-pages-data-analytics-ai-startup-js" */),
  "component---src-pages-data-analytics-ml-consulting-js": () => import("./../../src/pages/data-analytics-ml-consulting.js" /* webpackChunkName: "component---src-pages-data-analytics-ml-consulting-js" */),
  "component---src-pages-data-science-ml-company-js": () => import("./../../src/pages/data-science-ml-company.js" /* webpackChunkName: "component---src-pages-data-science-ml-company-js" */),
  "component---src-pages-data-science-online-courses-js": () => import("./../../src/pages/data-science-online-courses.js" /* webpackChunkName: "component---src-pages-data-science-online-courses-js" */),
  "component---src-pages-demo-js": () => import("./../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-digital-marketing-agency-js": () => import("./../../src/pages/digital-marketing-agency.js" /* webpackChunkName: "component---src-pages-digital-marketing-agency-js" */),
  "component---src-pages-event-details-js": () => import("./../../src/pages/event-details.js" /* webpackChunkName: "component---src-pages-event-details-js" */),
  "component---src-pages-events-js": () => import("./../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-form-download-js": () => import("./../../src/pages/form-download.js" /* webpackChunkName: "component---src-pages-form-download-js" */),
  "component---src-pages-gallery-js": () => import("./../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-hackathon-faq-js": () => import("./../../src/pages/hackathon/faq.js" /* webpackChunkName: "component---src-pages-hackathon-faq-js" */),
  "component---src-pages-hackathon-index-js": () => import("./../../src/pages/hackathon/index.js" /* webpackChunkName: "component---src-pages-hackathon-index-js" */),
  "component---src-pages-hackathon-peel-2020-js": () => import("./../../src/pages/hackathon/peel-2020.js" /* webpackChunkName: "component---src-pages-hackathon-peel-2020-js" */),
  "component---src-pages-hackathon-peel-2021-js": () => import("./../../src/pages/hackathon/peel-2021.js" /* webpackChunkName: "component---src-pages-hackathon-peel-2021-js" */),
  "component---src-pages-hackathon-swag-js": () => import("./../../src/pages/hackathon/swag.js" /* webpackChunkName: "component---src-pages-hackathon-swag-js" */),
  "component---src-pages-hackathon-volunteer-js": () => import("./../../src/pages/hackathon/volunteer.js" /* webpackChunkName: "component---src-pages-hackathon-volunteer-js" */),
  "component---src-pages-history-js": () => import("./../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-techna-js": () => import("./../../src/pages/index-techna.js" /* webpackChunkName: "component---src-pages-index-techna-js" */),
  "component---src-pages-jobs-apply-js": () => import("./../../src/pages/jobs/apply.js" /* webpackChunkName: "component---src-pages-jobs-apply-js" */),
  "component---src-pages-jobs-index-js": () => import("./../../src/pages/jobs/index.js" /* webpackChunkName: "component---src-pages-jobs-index-js" */),
  "component---src-pages-lms-js": () => import("./../../src/pages/lms.js" /* webpackChunkName: "component---src-pages-lms-js" */),
  "component---src-pages-machine-learning-ai-solutions-js": () => import("./../../src/pages/machine-learning-ai-solutions.js" /* webpackChunkName: "component---src-pages-machine-learning-ai-solutions-js" */),
  "component---src-pages-membership-levels-js": () => import("./../../src/pages/membership-levels.js" /* webpackChunkName: "component---src-pages-membership-levels-js" */),
  "component---src-pages-play-js": () => import("./../../src/pages/play.js" /* webpackChunkName: "component---src-pages-play-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profile-authentication-js": () => import("./../../src/pages/profile-authentication.js" /* webpackChunkName: "component---src-pages-profile-authentication-js" */),
  "component---src-pages-register-js": () => import("./../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-schedule-js": () => import("./../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-schedule-old-js": () => import("./../../src/pages/schedule-old.js" /* webpackChunkName: "component---src-pages-schedule-old-js" */),
  "component---src-pages-seo-agency-js": () => import("./../../src/pages/seo-agency.js" /* webpackChunkName: "component---src-pages-seo-agency-js" */),
  "component---src-pages-service-details-js": () => import("./../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-services-business-stationery-designing-js": () => import("./../../src/pages/services/business-stationery-designing.js" /* webpackChunkName: "component---src-pages-services-business-stationery-designing-js" */),
  "component---src-pages-services-contact-js": () => import("./../../src/pages/services/contact.js" /* webpackChunkName: "component---src-pages-services-contact-js" */),
  "component---src-pages-services-index-js": () => import("./../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-logo-designing-js": () => import("./../../src/pages/services/logo-designing.js" /* webpackChunkName: "component---src-pages-services-logo-designing-js" */),
  "component---src-pages-services-mobile-app-development-js": () => import("./../../src/pages/services/mobile-app-development.js" /* webpackChunkName: "component---src-pages-services-mobile-app-development-js" */),
  "component---src-pages-services-web-app-development-js": () => import("./../../src/pages/services/web-app-development.js" /* webpackChunkName: "component---src-pages-services-web-app-development-js" */),
  "component---src-pages-services-website-development-js": () => import("./../../src/pages/services/website-development.js" /* webpackChunkName: "component---src-pages-services-website-development-js" */),
  "component---src-pages-services-website-development-pakistan-js": () => import("./../../src/pages/services/website-development-pakistan.js" /* webpackChunkName: "component---src-pages-services-website-development-pakistan-js" */),
  "component---src-pages-success-stories-js": () => import("./../../src/pages/success-stories.js" /* webpackChunkName: "component---src-pages-success-stories-js" */),
  "component---src-pages-swag-js": () => import("./../../src/pages/swag.js" /* webpackChunkName: "component---src-pages-swag-js" */),
  "component---src-pages-team-js": () => import("./../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-testimonials-js": () => import("./../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-ws-js": () => import("./../../src/pages/ws.js" /* webpackChunkName: "component---src-pages-ws-js" */)
}

